<template>
    <div>
        <!-- Title and Logo -->
        <HeadTitle />
        <!-- end Logo and Title -->
        <div v-if="jenis > 41 || !jenis">
            <b-card>
                <b-alert variant="warning" show class="text-center">
                    <div class="h5 font-weight-bold">
                        Wajib Pajak Perorangan Tidak Dapat Menambah Wakil NPWPD.
                    </div>
                </b-alert>
            </b-card>
        </div>
        <!-- Panel Start here -->
        <panel title="LIST Wakil Npwpd" class="panel panel-danger" v-if="jenis < 41 || jenis">
            <!-- <template slot="header">
                <h6 class="font-weight-bold mr-auto"> LIST PEMBERI PARTISIPASI</h6>
            </template> -->
            <div class="pl-4 py-2 border shadow-sm">
                <span
                    ><b-button variant="primary" to="/user/wakil/tambah" pill
                        ><i class="fa fa-plus"></i> Tambah Wakil</b-button
                    ></span
                >
                <span class="px-2"
                    ><b-button variant="primary" @click="reload" pill
                        ><i class="fa fa-redo-alt pr-2"></i> Refresh</b-button
                    ></span
                >
            </div>
            <div class="py-3">
                <b-card class="shadow-sm rounded-0">
                    <!-- <vue-good-table 
						:columns="columns" 
						:rows="rows" 
						:lineNumbers="true"
						:search-options="{ enabled: true, placeholder: 'Search this table' }" 
						:pagination-options="{
							enabled: true,
							mode: 'records',
							perPage: 10,
							position: 'bottom',
							perPageDropdown: [15, 20, 25],
							dropdownAllowAll: true,
							setCurrentPage: 1,
							nextLabel: 'next',
							prevLabel: 'prev',
							rowsPerPageLabel: 'Rows per page',
							ofLabel: 'of',
							pageLabel: 'page', // for 'pages' mode
							allLabel: 'All',
						}"
					>
						<template slot="table-column" slot-scope="props">
							<span style="white-space: pre;">{{ props.column.label}}</span>
						</template>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field === 'action' ">
								<b-dropdown split class="m-r-2" size="sm" variant="info">
									<template #button-content>
										<i class="fa fa-cogs"></i> Aksi
									</template>
									<b-dropdown-item><i class="fa fa-edit"></i> Edit</b-dropdown-item>
									<b-dropdown-item @click="swalNotification('error')"><i class="fa fa-trash-alt"></i> Hapus</b-dropdown-item>
								</b-dropdown>
							</span>
						</template>
					</vue-good-table> -->
                    <!-- end Tools & Search -->

                    <!-- Data Table & Pagination -->
                    <b-table
                        show-empty
                        small
                        id="masTable"
                        ref="masTable"
                        head-variant="dark"
                        :hover="true"
                        :busy.sync="isBusy"
                        :items="myGetData"
                        :fields="fields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        responsive
                        bordered
                        striped
                    >
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>

                        <template v-slot:table-colgroup="scope">
                            <col
                                v-for="field in scope.fields"
                                :key="field.key"
                                :style="{
                                    width:
                                        field.key === 'actions' ? '120px' : '',
                                }"
                            />
                        </template>

                        <!-- nama npwpd -->
                        <template #cell(nama)="data">
                            {{ data.item.nama }} <br />
                        </template>

                        <!-- email dan telepon -->
                        <template v-slot:cell(telp)="data">
                            email:
                            {{ data.item.email }} <br />
                            telepon:
                            {{ data.item.notelepon }}
                        </template>

                        <!-- status wakil -->
                        <template v-slot:cell(aktif)="data">
                            <span v-if="data.item.aktif">
                                <span
                                    v-if="data.item.aktif === 1"
                                    class="label label-success"
                                >
                                    Aktif
                                </span>
                                <span v-else class="label label-danger">
                                    Non Aktif
                                </span>
                            </span>
                        </template>

						<!-- alamat -->
						<template #cell(alamat)="data">
							{{ data.item.alamat }},
							Kel. {{ data.item.wilayah_kelurahan.nama }}
						</template>

                        <template #head()="data">
                            <span style="white-space: pre;">{{
                                data.label
                            }}</span>
                        </template>

                        <!-- button actions -->
                        <template v-slot:cell(actions)="data">
                            <b-dropdown
                                split
                                class="m-r-2 d-flex align-item-center"
                                size="sm"
                                variant="info"
                            >
                                <template #button-content>
                                    <i class="fa fa-cogs"></i> Aksi
                                </template>
                                <b-dropdown-item
                                    @click="detailNPWP(data.item.id)"
                                    ><i class="fa fa-eye"></i>
                                    Lihat</b-dropdown-item
                                >
                                <!-- <b-dropdown-item @click="editNpwp(data.item.id)"><i class="fa fa-edit"></i> Edit</b-dropdown-item>
							<b-dropdown-item @click="swalNotification('error', data.item.id)"><i class="fa fa-trash-alt"></i> Hapus</b-dropdown-item> -->
                            </b-dropdown>
                        </template>
                        <!-- end button actions -->
                    </b-table>
                    <div class="divider"></div>
                </b-card>
            </div>
        </panel>
        <!-- Panel end here -->
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars

import Logo from "@/assets/images/logoSumut.png";
import axios from "axios";
import HeadTitle from "@/components/header/HRetribusi.vue";

export default {
    components: {
        HeadTitle,
    },
    data() {
        return {
            imageLogo: Logo,
            // Tools
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,
            roleArr: ["Maintenance", "Administrator", "Pimpinan", "Pegawai"],
            role: JSON.parse(localStorage.getItem("roles"))[0],
            user: JSON.parse(localStorage.getItem("user")),
            jenis: "",
            /*  columns: [{
				label: 'KODE',
				field: 'kode',
				tdClass: 'text-nowrap',
				thClass: 'text-nowrap'
			}, {
				label: 'NAMA URUSAN',
				field: 'nama',
				thClass: 'text-nowrap',
				tdClass: 'text-nowrap'
			}, {
				label: 'Opsi',
				field: 'action',
				type: 'percentage',
				width: '10%',
				tdClass: 'text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap',
				thClass: 'text-center text-nowrap'
			}],
      rows: [
        { id:1, kode: '1', nama:'Urusan Wajib'},
        { id:2, kode: '1', nama:'Urusan Wajib'},
        { id:3, kode: '1', nama:'Urusan Wajib'},
        { id:4, kode: '1', nama:'Urusan Wajib'},
	  ], */
            fields: [
                {
                    key: "index",
                    label: "No.",
                },
                {
                    key: "nama",
                    label: "Nama Pimpinan",
                    sortable: true,
                },
                {
                    key: "jabatan",
                    label: "Jabatan",
                    sortable: true,
                },
                {
                    key: "alamat",
                    label: "Alamat",
                    sortable: true,
                },
                {
                    key: "telp",
                    label: "Telepon & Email",
                },
                {
                    key: "aktif",
                    label: "Status",
                },
                {
                    key: "actions",
                    label: "Opsi",
                },
            ],
        };
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {
                        text: f.label,
                        value: f.key,
                    };
                });
        },
        /* ...mapState([
		'role'
	]) */
    },
    mounted() {
        this.getDetailNpwpd(this.user.npwpd_id);
    },
    methods: {
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/wajib-pajak/npwpd-wakil", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                },
            });
            return promise
                .then((response) => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    console.log(items)
                    return items;
                })
                .catch((error) => {
                    // console.error(error);
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    if (error.response.error == 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },
        updatePage() {
            this.$router.go({ name: "Pemberitahuan" });
        },
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Anda yakin ?",
                text: "Anda tidak akan bisa mengembalikan data!",
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Hapus",
                cancelButtonText: "Cancel",
                confirmButtonClass: "btn m-r-5 btn-" + btnClass + "",
                cancelButtonClass: "btn btn-default",
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete("/api/wajib-pajak/npwpd-wakil/" + id)
                        .then((response) => {
                            console.log(response.data);
                            this.$swal({
                                title: "Data Berhasil Dihapus!",
                                icon: "success",
                                type: "success",
                                showCloseButton: true,
                                showConfirmButton: false,
                                timer: 1800,
                            });
                            this.reload();
                        })
                        .catch((error) => {
                            if (error.response.error == 401) {
                                localStorage.removeItem("EP_token");
                                this.$router.push({ name: "Login" });
                            }
                        });
                }
            });
        },
        detailNPWP(index) {
            this.$router.push({
                name: "DetailWakilUser",
                params: { id: index },
            });
        },
        editNpwp(index) {
            this.$router.push({ name: "EditWakilUser", params: { id: index } });
        },

        getDetailNpwpd(id) {
            axios
                .get("/api/wajib-pajak/npwpd/" + id)
                .then((response) => {
                    this.jenis = response.data.data.jenis;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
    },
};
</script>

<style scoped>
.modal-sm {
    width: 200px;
}
</style>
